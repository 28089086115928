import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo from "../../assets/logo_topo.png";
import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="top">
            <figure className="left">
              <img src={logo} alt="Logo" />
            </figure>

            <CtaWpp text={"(41) 99602-8698"} />
          </div>

          <h1 className="title">
            INSTALAÇÃO E MANUTENÇÃO DE <span>AR-CONDICIONADO</span>
          </h1>

          <p className="text">Empresarial e residencial.</p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">NOSSOS SERVIÇOS</h2>

          <div className="gallery">
            <div className="item">
              <figure className="left">
                <img src={header2_1} alt="Homem consertando ar condicionado" />
              </figure>

              <div className="right">
                <h2 className="title">INSTALAÇÃO DE AR-CONDICIONADO</h2>

                <p className="text">
                  Somos especializados em instalação de ar-condicionado em sua
                  residência ou empresa.
                </p>

                <CtaWpp />
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={header2_2} alt="Homem consertando ar condicionado" />
              </figure>

              <div className="right">
                <h2 className="title">MANUTENÇÃO DE AR-CONDICIONADO </h2>

                <p className="text">
                  Contamos com uma equipe de profissionais especializados em
                  manutenção de ar-condicionado de todas as marcas e modelos.
                </p>

                <CtaWpp />
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
