import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about2 from "../../assets/about2.png";
import about2Mobile from "../../assets/mobile/about2.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">
            Passagem de infraestrutura para ar-condicionado
          </h2>

          <p className="text">
            <b>
              Executamos infraestrutura para obras de pequeno e grande porte. Ao
              construir ou reformar, pense na infraestrutura para o ar
              condicionado. Mesmo que você não compre e instale os equipamentos
              imediatamente, deixe tudo preparado.
            </b>
            Além de valorizar o imóvel no momento de uma possível venda, não
            custa tão caro assim e precisa ser executado durante a obra, pois
            depois vai necessariamente significar quebradeira e um grande
            arrependimento. Mas lembre-se, consulte a CWB CLIMATIZAÇÃO para
            saber qual a solução mais adequada para o seu caso.
          </p>

          <CtaWpp />
        </div>
      </article>

      <article id="mobile">
        <div className="container">
          <p className="text">
            <b>
              Executamos infraestrutura para obras de pequeno e grande porte. Ao
              construir ou reformar, pense na infraestrutura para o ar
              condicionado. Mesmo que você não compre e instale os equipamentos
              imediatamente, deixe tudo preparado.
            </b>
            Além de valorizar o imóvel no momento de uma possível venda, não
            custa tão caro assim e precisa ser executado durante a obra, pois
            depois vai necessariamente significar quebradeira e um grande
            arrependimento. Mas lembre-se, consulte a CWB CLIMATIZAÇÃO para
            saber qual a solução mais adequada para o seu caso.
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">PORQUE CONTRATAR A CWB CLIMATIZAÇÃO?</h2>

          <p className="text">
            Somos uma empresa de climatização que preza pela qualidade na
            entrega dos nossos serviços, garantindo a satisfação dos nossos
            clientes.
          </p>

          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about2Mobile
                  : about2
              }
              alt="Garantias e Qualidades"
            />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">SOLICITE UM ORÇAMENTO! </h2>

          <p className="text">Fale conosco via WhatsApp:</p>

          <CtaWpp text={"(41) 99602-8698"} />
        </div>
      </article>
    </section>
  );
};

export default About;
